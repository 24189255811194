import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { useAuthStore, useInitStore } from '../../../stores';
import {
  AccountFullSvg,
  AvatarUploader,
  ProfileFullSvg,
  TitleIcon,
} from '../../../common';
import ProfileDetails from './profile-details.component';
import AccountDetails from './account-details.component';
import {
  clearMixpanelUser,
  trackMixpanelEvent,
} from '../../../third-party/libraries/mixpanel';

import '../../../common/form.css';

import './account.component.css';

const Account: React.FC = () => {
  const navigate = useNavigate();

  const clearToken = useAuthStore((state) => state.clearToken);

  const { user, resetInitData } = useInitStore();

  return (
    <div className="account-container">
      <AvatarUploader
        templateId={process.env.REACT_APP_TRANSLOADIT_TEMPLATE_USER_AVATAR!}
        fields={{ user_id: user?.id }}
        initialValue={user?.avatarUrl}
        size={90}
        style={{ marginTop: '28px', marginBottom: '20px' }}
      />

      <div className="inner-wrapper">
        <TitleIcon
          label="Profile"
          style={{
            marginLeft: '16px',
            marginBottom: '8px',
          }}
        >
          <Icon component={ProfileFullSvg} style={{ width: '22px' }} />
        </TitleIcon>

        <div className="content">
          <ProfileDetails />
        </div>
      </div>

      <div className="inner-wrapper">
        <TitleIcon
          label="Account"
          style={{
            marginLeft: '16px',
            marginTop: '28px',
            marginBottom: '8px',
          }}
        >
          <Icon component={AccountFullSvg} style={{ width: '22px' }} />
        </TitleIcon>

        <div className="content">
          <AccountDetails />
        </div>
      </div>

      {/* wrapping with an extra div to not affect the footer */}
      <div style={{ width: '100%' }}>
        <div className="form" style={{ width: '100%', padding: '0px' }}>
          <Button
            type="primary"
            htmlType="submit"
            className="form-button light"
            style={{ marginTop: '28px', maxWidth: '350px' }}
            onClick={() => {
              resetInitData();
              clearToken();
              navigate('/');

              trackMixpanelEvent('Sign Out');
              clearMixpanelUser();
            }}
          >
            Sign Out
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Account;
