import { Layout } from 'antd';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useChat } from '../../../common';
import { RouteData } from '../../../utils';
import ChatInput from './chat-input/chat-input.component';

const { Footer } = Layout;

const ChatFooter: FC<{
  routeData: RouteData;
  maxWidth?: string;
  setVisible: (value: boolean) => void;
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({ routeData, maxWidth, setVisible, style, ...props }) => {
  const params = useParams();

  const { sendMessage, sendVoiceMessage, isLoading, isSendingMessage, tab } =
    useChat(params.handle, true);

  const isVisible = tab === 'chat';

  useEffect(() => {
    setVisible(isVisible);
  }, [routeData, tab]);

  if (!isVisible) return <></>;

  return (
    <Footer style={{ width: '100%', maxWidth, ...style }} {...props}>
      <ChatInput
        onSubmitText={sendMessage}
        onSubmitAudio={sendVoiceMessage}
        disabled={isLoading || isSendingMessage}
      />
    </Footer>
  );
};

export default ChatFooter;
