import { useEffect, useRef } from 'react';
import { Socket, io } from 'socket.io-client';
import { Buffer } from 'buffer';
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';
import {
  AudioContext,
  IAudioBufferSourceNode,
  IAudioContext,
} from 'standardized-audio-context';

import { ChatCharacter } from '../../../api';
import {
  useCallStore,
  useInitStore,
  useAuthStore,
  useMiscStore,
} from '../../../stores';
import { createNotification, NotificationType } from '../../../utils';
import { AuthDrawerState } from '../../components';
import { CallState, CallEvent, PermissionStatus } from './enums';

const FREQUENCY = 16000;
const TIME_SLICE = 300;
const SAMPLE_RATE = 44100;
// const SAMPLE_RATE = 24000;
const wav = false;

export const useCall = (character?: ChatCharacter) => {
  const {
    secondsElapsed,
    setSecondsElapsed,
    inProgress,
    setInProgress,
    state,
    setState,
    reset,
    setCharacter,
    requestEnd,
    setRequestEnd,
  } = useCallStore();

  const setBalance = useInitStore((state) => state.setBalance);
  const token = useAuthStore((state) => state.token);

  const { setWalletOpen, setSubscriptionModalOpen, setAuthOpen } =
    useMiscStore();

  const socket = useRef<Socket>();
  const stream = useRef<MediaStream>();
  const recorder = useRef<RecordRTCPromisesHandler>();
  const audioContext = useRef<AudioContext>();
  const audioSource = useRef<IAudioBufferSourceNode<IAudioContext> | null>();
  const nextStartTime = useRef<number>(0);
  const startedTime = useRef<Date>();
  const tickInterval = useRef<any>();

  //
  const base64ToArrayBuffer = (base64: string) => {
    const binaryData = atob(base64);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++)
      uint8Array[i] = binaryData.charCodeAt(i);

    return { arrayBuffer, length: uint8Array.length };
  };

  const createAudioBuffer = (arrayBuffer: ArrayBuffer, length: number) => {
    const data = new DataView(arrayBuffer);

    const audioBuffer = audioContext.current!.createBuffer(
      1,
      length / 2,
      SAMPLE_RATE,
    );
    const channelData = audioBuffer.getChannelData(0);

    for (let i = 0; i < data.byteLength; i += 2) {
      const sample = data.getInt16(i, true);

      channelData[i / 2] = sample / 32768;
    }

    return audioBuffer;
  };
  //

  const createAudioSource = async (
    base64: string,
  ): Promise<{
    buffer: AudioBuffer;
    source: IAudioBufferSourceNode<IAudioContext>;
  }> => {
    try {
      let audioBuffer: AudioBuffer;

      if (wav) {
        const { arrayBuffer, length } = base64ToArrayBuffer(base64);

        audioBuffer = createAudioBuffer(arrayBuffer, length);
      } else {
        const uint8array = Buffer.from(base64, 'base64');

        audioBuffer = await audioContext.current!.decodeAudioData(
          uint8array.buffer,
        );
      }

      const source: IAudioBufferSourceNode<IAudioContext> =
        audioContext.current!.createBufferSource();

      source.connect(audioContext.current!.destination);

      source.buffer = audioBuffer;

      return { buffer: audioBuffer, source };
    } catch (e) {
      console.error(e);

      return { buffer: null, source: null } as any;
    }
  };

  const createVoiceChunkPromise = async (base64: string) => {
    const { buffer, source } = await createAudioSource(base64);

    if (!buffer) return; // happens when decodeAudioData fails in createAudioSource

    if (nextStartTime.current < audioContext.current!.currentTime) {
      nextStartTime.current = audioContext.current!.currentTime;
    }

    source.onended = () => setState(CallState.Listening);

    source.start(nextStartTime.current);

    audioSource.current = source;
    nextStartTime.current += buffer.duration;
  };

  const interrupt = () => {
    if (audioSource.current) {
      audioSource.current.stop();
      audioSource.current.disconnect();

      audioSource.current = null;
      nextStartTime.current = 0;
    }
  };

  const end = () => {
    recorder.current?.stopRecording();
    stream.current?.getAudioTracks().forEach((value) => value.stop());
    socket.current?.close();
    audioContext.current?.close();

    setInProgress(false);

    clearInterval(tickInterval.current);

    recorder.current = undefined;
    stream.current = undefined;
    socket.current = undefined;
    audioContext.current = undefined;

    nextStartTime.current = 0;
    startedTime.current = undefined;
    tickInterval.current = undefined;
  };

  const replaceProtocol = (url: string) => {
    return url.replace(/^http:\/\//, 'ws://').replace(/^https:\/\//, 'wss://');
  };

  const connect = () => {
    setState(CallState.Connecting);

    const host = replaceProtocol(process.env.REACT_APP_API!);

    socket.current = io(host, {
      path: '/call',
      reconnectionDelayMax: 10000,
      auth: { token, characterId: character?.id },
    });

    /*socket.current.on(CallEvent.Disconnect, (reason: any) => {
      if (reason === 'io server disconnect') {
        console.error('Connection failed');
      } else console.error('disconnect', reason);

      end(); // TODO: pass reason?
    });*/

    socket.current.on(CallEvent.Disconnect, end);

    socket.current.on(CallEvent.Exception, (message: string) => {
      if (message === 'Insufficient balance') {
        setWalletOpen(true, true);
      } else if (message === 'Subscription required') {
        setSubscriptionModalOpen(true);
      } else {
        createNotification({
          key: 'serverError',
          message: message,
          type: NotificationType.Error,
        });
      }

      end();
    });

    socket.current.on(CallEvent.Ready, () => {
      startedTime.current = new Date();

      setSecondsElapsed(0);

      tickInterval.current = setInterval(() => {
        const currentTime = new Date().getTime();
        const startTime = startedTime.current!.getTime();
        const result = Math.floor((currentTime - startTime) / 1000);

        setSecondsElapsed(result);
      }, 500);
    });

    socket.current.on(CallEvent.VoiceChunk, createVoiceChunkPromise);

    socket.current.on(CallEvent.State, (state: CallState) => {
      if (state === CallState.Thinking) interrupt();

      setState(state);
    });

    socket.current.on(CallEvent.Balance, setBalance);
  };

  const requestMicrophonePermission = async () => {
    if (stream.current?.active) return PermissionStatus.Success;

    try {
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
    } catch (e: any) {
      if (e.message === 'Permission dismissed') {
        return PermissionStatus.Dismissed;
      }

      return PermissionStatus.Blocked;
    }

    return PermissionStatus.Success;
  };

  const start = async () => {
    if (!token) {
      setAuthOpen(true, AuthDrawerState.SignUp);

      return PermissionStatus.Success;
    }

    if (inProgress) return PermissionStatus.Success;

    const permissionStatus: PermissionStatus =
      await requestMicrophonePermission();

    if (permissionStatus !== PermissionStatus.Success) {
      createNotification({
        key: 'permissionError',
        message: 'Please allow microphone access',
        type: NotificationType.Error,
      });

      return permissionStatus;
    }

    audioContext.current = new AudioContext({ sampleRate: SAMPLE_RATE });

    reset();
    setCharacter(character);
    setInProgress(true);

    recorder.current = new RecordRTCPromisesHandler(stream.current!, {
      type: 'audio',
      timeSlice: TIME_SLICE,
      mimeType: 'audio/wav',
      desiredSampRate: FREQUENCY,
      numberOfAudioChannels: 1,
      disableLogs: true,
      recorderType: StereoAudioRecorder,
      ondataavailable: async (blob: Blob) => {
        const byteArray: ArrayBuffer = await blob.arrayBuffer();

        const base64: string = Buffer.from(byteArray).toString('base64');

        if (socket.current?.connected) {
          socket.current?.emit('speech', { data: base64 });
        }
      },
    });

    recorder.current.startRecording();

    connect(); // only connect once recording permission is verified?

    return permissionStatus;
  };

  useEffect(() => {
    if (requestEnd) {
      // the server should be the one initiating the actual socket disconnection, in order to emit balance beforehand
      socket.current?.emit(CallEvent.RequestEnd);

      setRequestEnd(false);
    }
  }, [requestEnd]);

  return { start, end, inProgress, state, secondsElapsed };
};
