import { FC } from 'react';
import { Form, Input, Button } from 'antd';

import { SignUpRequest } from '../../../api';
import { useMiscStore } from '../../../stores';
import { AuthDrawerState, StatefulLink } from '../../../common';
import { AuthType } from './auth-type.enum';
import GoogleAuth from './google-auth.component';
import { useAuthRequest } from './use-auth-request.hook';

import '../../form.css';

const SignUp: FC = () => {
  const [form] = Form.useForm();

  const setAuthOpen = useMiscStore((state) => state.setAuthOpen);

  const { requestForm, requestGoogle, isLoading } = useAuthRequest({
    form,
    formConfigFactory: SignUpRequest,
    onSuccess: () => setAuthOpen(false),
  });

  return (
    <div className="form">
      <div className="title">Sign Up</div>

      <GoogleAuth
        authType={AuthType.SignUp}
        onSuccess={requestGoogle}
        loading={isLoading}
      />

      <div className="divider" />

      <Form
        form={form}
        onFinish={(params) =>
          requestForm({ ...params, path: location.pathname })
        }
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        disabled={isLoading}
        style={{ width: '100%' }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Email must not be empty',
            },
            {
              type: 'email',
              message: 'Email is invalid',
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Password must not be empty' }]}
        >
          <Input type="password" placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="confirm_password"
          rules={[
            { required: true, message: 'Confirm password must not be empty' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value)
                  return Promise.resolve();

                return Promise.reject(new Error("Passwords don't match"));
              },
            }),
          ]}
        >
          <Input type="password" placeholder="Confirm password" />
        </Form.Item>

        <Form.Item style={{ marginTop: '8px', marginBottom: '0px' }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="form-button"
          >
            Sign Up
          </Button>
        </Form.Item>
      </Form>

      <div className="terms">
        By clicking Sign up, you agree to our{' '}
        <StatefulLink
          to="/tos"
          target="_blank"
          disabled={isLoading}
          className="link"
        >
          Terms
        </StatefulLink>{' '}
        and{' '}
        <StatefulLink
          to="/privacy"
          target="_blank"
          disabled={isLoading}
          className="link"
        >
          Privacy Policy
        </StatefulLink>
      </div>

      <div className="switch">
        Already have an account?{' '}
        <StatefulLink
          onClick={() => setAuthOpen(true, AuthDrawerState.SignIn)}
          disabled={isLoading}
          className="link"
        >
          Sign In
        </StatefulLink>
      </div>
    </div>
  );
};

export default SignUp;
