import { Form, Radio, Select } from 'antd';
import { RuleObject } from 'antd/es/form';

import { StepProps } from '../step-props.interface';
import { capitalizeFirstLetter, valuesToOptions } from '../../../../../utils';

import '../../../../../common/form.css';

export enum ResponseLength {
  Concise = 'concise',
  Medium = 'medium',
  Elaborate = 'elaborate',
}

function tagsValidator(label: string) {
  return (rule: RuleObject, value: string[]) => {
    const regex = /^[A-Za-z\s]*$/;

    const invalidItems = value.filter((item) => !item.match(regex));

    if (invalidItems.length > 0) {
      return Promise.reject(
        new Error(
          `${capitalizeFirstLetter(
            label,
          )}s can only contain letters and spaces. Invalid ${label}${
            invalidItems.length > 1 ? 's' : ''
          }: ${invalidItems.join(', ')}`,
        ),
      );
    }

    return Promise.resolve();
  };
}

const Preferences: React.FC<StepProps> = () => {
  return (
    <>
      <Form.Item
        name="expertSubjects"
        label="What subjects are you an expert in?"
        rules={[{ validator: tagsValidator('subject') }]}
      >
        <Select
          mode="tags"
          allowClear
          placeholder="Enter or select a subject"
          options={valuesToOptions(['Soccer', 'Basketball', 'Gaming'])}
          style={{ maxWidth: '800px' }}
        />
      </Form.Item>

      <Form.Item
        name="likeSubjects"
        label="What subjects do you like talking about?"
        rules={[{ validator: tagsValidator('subject') }]}
      >
        <Select
          mode="tags"
          allowClear
          placeholder="Enter or select a subject"
          options={valuesToOptions(['Food', 'Playstation', 'TV Series'])}
          style={{ maxWidth: '800px' }}
        />
      </Form.Item>

      <Form.Item
        name="dislikeSubjects"
        label="What subjects do you wish to not talk about?"
        rules={[{ validator: tagsValidator('subject') }]}
      >
        <Select
          mode="tags"
          allowClear
          placeholder="Enter or select a subject"
          options={valuesToOptions(['Sex', 'Politics'])}
          style={{ maxWidth: '800px' }}
        />
      </Form.Item>

      <Form.Item
        name="responseLength"
        label="How long would you like the responses to be?"
        rules={[{ required: true, message: 'Response length is required' }]}
      >
        <Radio.Group>
          <Radio.Button value={ResponseLength.Concise}>Concise</Radio.Button>

          <Radio.Button value={ResponseLength.Medium}>Medium</Radio.Button>

          <Radio.Button value={ResponseLength.Elaborate}>
            Elaborate
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default Preferences;
