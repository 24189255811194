import { Space } from 'antd';
import Icon from '@ant-design/icons';

import {
  CharacterShowcaseRequest,
  ChatCharacter,
  ShowcaseArea,
  UserType,
  useAPIRequest,
} from '../../../api';
import {
  CharacterHighlight,
  ChatSvg,
  CreatorSvg,
  DiscordSvg,
  If,
  Loader,
  WideButton,
} from '../../../common';
import IndexCreators from './index-creators.component';
import { useInitStore } from '../../../stores';

import './index.component.css';

const Index: React.FC = () => {
  const { user } = useInitStore();

  const { response, isLoading, error } = useAPIRequest<{
    data: ChatCharacter[];
  }>(() => CharacterShowcaseRequest(ShowcaseArea.Homepage));

  return (
    <Loader isLoading={isLoading} error={error}>
      <div className="index-container">
        <CharacterHighlight
          data={response?.data?.[0]}
          nameTag
          popularTag
          style={{ marginBottom: '34px' }}
        />

        <div className="title" style={{ marginBottom: '24px' }}>
          Chat with your Favorite Creators 24/7
        </div>

        <WideButton
          label="Chat Now"
          to={`/${response?.data?.[0]?.handle}/chat`}
          accent
          style={{ width: '178px' }}
        >
          <Icon component={ChatSvg} style={{ width: '20px' }} />
        </WideButton>

        <IndexCreators
          data={response?.data?.slice(1, response?.data?.length)}
          style={{ marginTop: '64px', marginBottom: '64px' }}
        />

        <Space
          direction="vertical"
          size={12}
          style={{ width: '100%', maxWidth: '360px' }}
        >
          <WideButton
            label="Discord"
            to={process.env.REACT_APP_DISCORD}
            target="_blank"
            light
          >
            <Icon component={DiscordSvg} style={{ width: '16px' }} />
          </WideButton>

          <If condition={!user || user?.type === UserType.Normal}>
            <WideButton
              label="Creator?"
              to={process.env.REACT_APP_CREATORS}
              target="_blank"
              light
            >
              <Icon component={CreatorSvg} style={{ width: '16px' }} />
            </WideButton>
          </If>
        </Space>
      </div>
    </Loader>
  );
};

export default Index;
