import React, { ReactElement } from 'react';
import { Divider } from 'antd';

export function divideComponents<T>({
  items,
  itemFactory,
  keyFactory,
  dividerFactory,
}: {
  items: T[];
  itemFactory: (item: T) => ReactElement;
  keyFactory: (item: T) => string;
  dividerFactory?: () => ReactElement;
}) {
  return items.map((item, index) => (
    <React.Fragment key={keyFactory(item)}>
      {itemFactory(item)}
      {index < items.length - 1 &&
        (dividerFactory ? dividerFactory() : <Divider />)}
    </React.Fragment>
  ));
}

export function limitTextLength(text?: string, maxLength?: number) {
  if (!text || !maxLength) return text;

  if (maxLength && text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }

  return text;
}

export function limitTextLengthByWords(text?: string, maxWords?: number) {
  if (!text) return text;

  const words = text.split(/\s+/);

  if (!maxWords || words.length <= maxWords) return text;

  let truncatedText = words.slice(0, maxWords).join(' ');

  truncatedText = truncatedText.replace(/[\.,;!?]$/, '');

  return truncatedText + '...';
}

export function textToLines(
  text?: string,
  options?: { props?: any; maxLength?: number },
) {
  if (!text) return [];

  return limitTextLength(text, options?.maxLength)!
    .split('\n')
    .map((innerText, index) => (
      <div key={`line_${index}`} {...options?.props}>
        {innerText}
      </div>
    ));
}

export function capitalizeFirstLetter(value: string): string {
  return value.charAt(0).toUpperCase() + value.substring(1);
}

export function formatDuration(seconds: number) {
  if (seconds === null || seconds === undefined) return undefined;

  const hours = Math.floor(seconds / 3600);

  seconds %= 3600; // update seconds to remain after subtracting hours

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  let formattedTime = `${formattedMinutes}:${formattedSeconds}`;

  if (hours > 0) formattedTime = `${formattedHours}:${formattedTime}`;

  return formattedTime;
}

export function cssObjectToString(object: any) {
  const classNames: string[] = [];

  Object.entries(object).forEach(([key, value]) => {
    if (key && value) classNames.push(key);
  });

  return classNames.join(' ');
}

export function valuesToOptions(values: string[]) {
  return values.map((value: string) => ({ label: value, value }));
}
