import { AxiosRequestConfig } from 'axios';

import { CharacterParams } from '../character';

export const UpdateCreatorCharacterRequest = (
  params: Omit<CharacterParams, 'id'>,
): AxiosRequestConfig => {
  return {
    url: `v1/creator/character`,
    method: 'put',
    data: params,
  };
};
