import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import Init from '../routes/app/init/init.component';
import { useAuthStore, useInitStore, usePersistedStore } from '../stores';

export function useLayoutChildren(children?: ReactNode | undefined) {
  const { initialized, user } = useInitStore();

  const token = useAuthStore((state) => state.token);
  const setDefaultPath = usePersistedStore((state) => state.setDefaultPath);

  if (initialized && user) {
    if (!user.emailConfirmed) {
      return <Navigate to="/confirm-email" replace />;
    } else if (user.name && user.gender) return children;
    else {
      // not a pretty solution
      // throws an error of updating the state during rendering
      // TODO: find a prettier solution
      setDefaultPath(location.pathname);

      return <Navigate to="/onboarding" replace />;
    }
  }

  return token ? <Init /> : children;
}

// currently rendering a "required" route, e.g. confirm-email. used in PersistDefaultPath component to not reset defaultPath when rendering required routes
export function useLayoutRequired() {
  const { initialized, user } = useInitStore();

  return (
    !initialized ||
    (initialized && (!user?.emailConfirmed || !user?.name || !user?.gender))
  );
}
