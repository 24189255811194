import { UserParams } from '../../../api';
import { AppLayout } from '../../../layouts/app/app-layout.component';
import { RouteData } from '../../../utils';
import Creator from './creator.component';
import Media from './media/media.component';
import Medias from './medias/medias.component';
import { Payouts } from './payouts/payouts.component';
import Product from './product/product.component';
import Products from './products/products.component';
import Profile from './profile/profile.component';
import Waitlist from './waitlist/waitlist.component';

export const CreatorRoutes: RouteData[] = [
  {
    path: 'start',
    title: 'Get Cloned!',
    component: Waitlist,
    layout: AppLayout,
    hideFooter: true,
    maxWidth: '100%',
  },
  {
    path: 'start/:promo',
    title: 'Get Cloned!',
    component: Waitlist,
    layout: AppLayout,
    hideFooter: true,
    maxWidth: '100%',
  },
  {
    path: 'creator',
    titleFactory: (user?: UserParams) => {
      return user?.creatorOnboardingCompleted ? 'Overview' : 'Onboarding';
    },
    component: Creator,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    // TODO: user type must be creator
  },
  {
    path: 'creator/media',
    title: 'Media',
    component: Medias,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/creator',
  },
  {
    path: 'creator/media/:mediaId',
    title: 'Media',
    component: Media,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/creator/media',
  },
  {
    path: 'creator/products',
    title: 'Products',
    component: Products,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/creator',
  },
  {
    path: 'creator/product/:productId',
    title: 'Products',
    component: Product,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/creator/products',
  },
  {
    path: 'creator/payouts',
    title: 'Payouts',
    component: Payouts,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/creator',
  },
  {
    path: 'creator/profile',
    title: 'Profile',
    component: Profile,
    layout: AppLayout,
    authRequired: true,
    hideFooter: true,
    maxWidth: '100%',
    backPath: '/creator',
  },
];
