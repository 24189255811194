import { FC, PropsWithChildren } from 'react';

import { If } from '../if.component';

import './classic-modal.component.css';

export const ClassicModal: FC<
  PropsWithChildren<{
    isOpen?: boolean;
    close?: () => void;
    width?: string;
    maxWidth?: string;
  }>
> = ({ isOpen, close, width, maxWidth, children }) => {
  return (
    <If condition={isOpen}>
      <div className="classic-modal-wrapper">
        <div className="classic-modal-background" onClick={close} />

        <div className="classic-modal-content" style={{ width, maxWidth }}>
          {children}
        </div>
      </div>
    </If>
  );
};
