import { Avatar } from 'antd';

import { CallState, ClassicModal, WideButton } from '../../../../common';
import { useCallStore } from '../../../../stores';
import { formatDuration } from '../../../../utils';

import './call-modal.component.css';

const CallModal: React.FC = () => {
  const { inProgress, setRequestEnd, character, state, secondsElapsed } =
    useCallStore();

  return (
    <ClassicModal isOpen={inProgress} width="80%" maxWidth="600px">
      <div className="call-modal-container">
        <div
          style={{
            textAlign: 'center',
            fontSize: '28px',
            fontWeight: 'bold',
            color: 'var(--white)',
            marginBottom: '20px',
          }}
        >
          <Avatar size={200} src={character?.avatarUrl} />
        </div>

        <div
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            fontSize: '30px',
            color: 'var(--white)',
          }}
        >
          {character?.name}
        </div>

        <div
          style={{
            textAlign: 'center',
            marginBottom: '40px',
            fontSize: '16px',
            color: 'var(--grey6)',
          }}
        >
          {state === CallState.Connecting
            ? 'Connecting...'
            : formatDuration(secondsElapsed || 0)}
        </div>

        <WideButton
          label="End Call"
          red
          onClick={() => setRequestEnd(true)}
          style={{ marginBottom: '10px', maxWidth: '200px' }}
        />
      </div>
    </ClassicModal>
  );
};

export default CallModal;
