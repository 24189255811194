import { AppLayout } from '../../../layouts/app/app-layout.component';
import IndexHeader from '../../../layouts/index/index-header.component';
import { RouteData } from '../../../utils';
import Index from './index.component';

export const IndexRoutes: RouteData[] = [
  {
    path: '/',
    component: Index,
    layout: AppLayout,
    header: IndexHeader,
    authRequired: false,
  },
];
