import create from 'zustand';

import {
  UserParams,
  ReadUserRequest,
  useAPIRequest,
  SubscriptionPlan,
  ResponseType,
} from '../api';
import { isLocal } from '../common';
import { QueryValues } from '../utils';
import { setDatadogLogsUser } from '../third-party/libraries/datadog-logs';
import { setMixpanelUser } from '../third-party/libraries/mixpanel';

interface InitStoreState {
  initialized: boolean;
  user?: UserParams;
  setUser: (user: UserParams) => void;
  setEmail: (email: string, emailConfirmed?: boolean) => void;
  setBalance: (balance: number) => void;
  setResponseType: (responseType: ResponseType) => void;
  subscriptionPlans: SubscriptionPlan[];
  setSubscriptionPlans: (plans: SubscriptionPlan[]) => void;
  resetInitData: () => void;
  setInitialized: () => void;
  forwardedToken?: string; // invitation or forgot password
  setForwardedToken: (token: string) => void;
  queryValues?: QueryValues;
  setQueryValues: (queryValues: QueryValues) => void;
}

export const useInitStore = create<InitStoreState>((set) => ({
  initialized: false,
  subscriptionPlans: [],
  setUser: (user: UserParams) => {
    set({ user });

    if (!isLocal()) {
      // setDatadogRumUser(user);
      setDatadogLogsUser(user);
    }

    setMixpanelUser(user);
  },
  setEmail: (email: string, emailConfirmed?: boolean) => {
    set((prev) => ({
      user: { ...(prev.user as UserParams), email, emailConfirmed },
    }));
  },
  setBalance: (balance: number) => {
    set((prev) => ({
      user: { ...(prev.user as UserParams), balance },
    }));
  },
  setResponseType: (responseType: ResponseType) => {
    set((prev) => ({
      user: { ...(prev.user as UserParams), responseType },
    }));
  },
  setSubscriptionPlans: (subscriptionPlans: SubscriptionPlan[]) => {
    set({ subscriptionPlans });
  },
  resetInitData: () => {
    set({ initialized: false, user: undefined });
  },
  setInitialized: () => set({ initialized: true }),
  setForwardedToken: (token: string) => {
    set({ forwardedToken: token });
  },
  setQueryValues: (queryValues: QueryValues) => set({ queryValues }),
}));

export const useRefreshUser = () => {
  const setUser = useInitStore((state) => state.setUser);

  const { request, response } = useAPIRequest<UserParams>(ReadUserRequest, {
    immediate: false,
    onSuccess: setUser,
  });

  return { refreshUser: request, user: response };
};
