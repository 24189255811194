import { AxiosRequestConfig } from 'axios';

import { ShowcaseArea } from './showcase-area.enum';

export const CharacterShowcaseRequest = (
  area: ShowcaseArea,
): AxiosRequestConfig => {
  return {
    url: `v1/character/showcase`,
    method: 'get',
    params: { area },
  };
};
