import { CRUDKey } from '../../../common';
import { ImmediateRequest, Requests } from '../../hooks';
import { CharacterParams } from '../character';
import { ReadCreatorCharacterRequest } from './read-creator-character.request';
import { UpdateCreatorCharacterRequest } from './update-creator-character.request';

export function getCreatorCharacterRequests(): Requests<CharacterParams> {
  return {
    [CRUDKey.Read]: ImmediateRequest(ReadCreatorCharacterRequest),
    [CRUDKey.Update]: UpdateCreatorCharacterRequest,
  };
}
