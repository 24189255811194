import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Avatar, Layout, Space } from 'antd';
import Icon from '@ant-design/icons';

import { If, useLayoutDisplay } from '../../common';
import { BackSvg, MenuSvg } from '../layout-header-svg.component';
import { useInitStore, useMiscStore } from '../../stores';
import { RouteData } from '../../utils';

const { Header } = Layout;

const AccountHeader: FC<{
  routeData: RouteData;
  backPath: string;
  width?: string;
  maxWidth?: string;
  setVisible: (value: boolean) => void;
  style?: React.CSSProperties;
  [key: string]: any;
}> = ({
  routeData,
  backPath,
  width,
  maxWidth,
  setVisible,
  style,
  ...props
}) => {
  const { isMobile } = useLayoutDisplay();

  const { setMenuOpen } = useMiscStore();

  const user = useInitStore((state) => state.user);

  useEffect(() => {
    setVisible(true);
  }, [routeData]);

  return (
    <Header
      style={{
        width,
        maxWidth,
        borderRadius: routeData.flatHeader ? 0 : undefined,
        ...style,
      }}
      {...props}
    >
      <Helmet>
        <title>Swoons</title>
      </Helmet>

      <div className="header-container">
        <Space direction="horizontal" size={12}>
          <Link to={backPath}>
            <div className="button-circle">
              <Icon component={BackSvg} />
            </div>
          </Link>

          <Avatar size={48} src={user?.avatarUrl} />
        </Space>

        <div className="title">{user?.name}</div>

        {/* wrapping with div to maintain space when not on mobile */}
        <div>
          <If condition={isMobile}>
            <button className="button-menu" onClick={() => setMenuOpen(true)}>
              <Icon component={MenuSvg} style={{ width: '24px' }} />
            </button>
          </If>
        </div>
      </div>
    </Header>
  );
};

export default AccountHeader;
