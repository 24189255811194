import { useInitStore } from '../../../stores';
import CreatorOnboarding from './onboarding/creator-onboarding.component';
import CreatorOverview from './overview/creator-overview.component';
import { If } from '../../../common';

import '../../../common/form.css';
import './creator.component.css';

const Creator: React.FC = () => {
  const { user } = useInitStore();

  return (
    <div className="creator-container">
      <If condition={user?.creatorOnboardingCompleted}>
        <CreatorOverview />
      </If>

      <If condition={!user?.creatorOnboardingCompleted}>
        <CreatorOnboarding />
      </If>
    </div>
  );
};

export default Creator;
