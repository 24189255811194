import { useParams } from 'react-router-dom';

import MediaViewer from './media-viewer/media-viewer.component';
import MessageList from './message-list/message-list.component';
import { ParsedMessage } from './message-list/parsed-message.interface';
import { If, useChat } from '../../../../common';
import MediaGallery from './media-gallery/media-gallery.component';
import CallModal from '../call/call-modal.component';
import { ChatDrawer } from './chat-drawer/chat-drawer.component';
import { PricingDrawer } from './pricing-drawer/pricing-drawer.component';

import './chat.component.css';
import InitialChatSettingsModal from './initial-chat-settings-modal/initial-chat-settings-modal.component';

const Chat: React.FC = () => {
  const params = useParams();

  const {
    id: chatId,
    messages,
    loadMoreMessages,
    isLoadingMessages,
    viewedMedia,
    setViewedMedia,
    unlockMessageMedia,
    tab,
  } = useChat(params.handle);

  return (
    <>
      <If condition={tab === 'chat'}>
        <div className="chat-centered-container">
          <MessageList
            messages={messages}
            loadMoreMessages={loadMoreMessages}
            isLoadingMessages={isLoadingMessages}
            unlockMessageMedia={(data: ParsedMessage) => {
              unlockMessageMedia(chatId, data?.id);
            }}
            viewMedia={(data: ParsedMessage) => setViewedMedia(data.media)}
          />
        </div>
      </If>

      <If condition={tab === 'media'}>
        <MediaGallery chatId={chatId} setViewedMedia={setViewedMedia} />
      </If>

      <MediaViewer
        media={viewedMedia}
        close={() => setViewedMedia(undefined)}
      />

      <InitialChatSettingsModal />
      <CallModal />
      <ChatDrawer />
      <PricingDrawer />
    </>
  );
};

export default Chat;
