import { datadogLogs } from '@datadog/browser-logs';

import { UserParams } from '../../api';
import { getEnv } from '../../common';

export function initializeDatadogLogs() {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: `${process.env.REACT_APP_DATADOG_SERVICE}-${getEnv()}`,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
    beforeSend: (log) => {
      if (log.message?.startsWith('FBNav')) return false;

      return true;
    },
  });
}

export function setDatadogLogsUser({ id, ...user }: UserParams) {
  datadogLogs.setUser({ id: id.toString(), ...user });
}
