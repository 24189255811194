import { useRef, useState } from 'react';
import Uppy from '@uppy/core';
import Transloadit from '@uppy/transloadit';
import { Dashboard } from '@uppy/react';
import { Form, List, Space, Spin } from 'antd';
import Icon, { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import { StepProps } from '../step-props.interface';
import { useInitStore } from '../../../../../stores';
import {
  CreateVoiceSampleTransloaditAssembliesRequest,
  TransloaditAssemblyParams,
  TransloaditAssemblyStatus,
  VoiceSampleTransloaditAssemblyListRequest,
  VoiceSampleParams,
  useAPIRequest,
  DeleteVoiceSampleRequest,
  requestErrorHandler,
} from '../../../../../api';
import {
  NotificationType,
  confirmDangerousAction,
  createNotification,
} from '../../../../../utils';
import { If, PlaySvg } from '../../../../../common';
import { hasSufficientVoiceSamples } from '../steps';
import VoiceSampleProgress from './voice-sample-progress';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import '../../../../../common/form.css';

const REQUIRED_MINUTES = 2; // 30

const VoiceSamples: React.FC<StepProps> = ({ response, setResponse }) => {
  const user = useInitStore((state) => state.user);

  const assemblyId = useRef<string[]>([]);
  const list = useRef<VoiceSampleParams[]>(response?.voiceSamples || []);

  const {
    request: getTransloaditAssemblyList,
    isLoading: isLoadingTransloaditAssemblyList,
    response: transloaditAssemblyListResponse,
  } = useAPIRequest<{
    totalFilesProcessing: number;
    data: TransloaditAssemblyParams[];
  }>(() => VoiceSampleTransloaditAssemblyListRequest(user?.id || ''), {
    shouldRetry: (value) => value.data.length > 0,
    retryInterval: 3000,
    onSuccess: (value) => {
      let newVoiceSamples: VoiceSampleParams[] = [];

      value.data.map((assembly) => {
        if (assembly.status === TransloaditAssemblyStatus.Completed) {
          newVoiceSamples = newVoiceSamples.concat(assembly.voiceSamples);
        } else if (assembly.status === TransloaditAssemblyStatus.Error) {
          createNotification({
            key: 'serverError',
            message: assembly.error || 'Upload error',
            type: NotificationType.Error,
          });
        }
      });

      if (newVoiceSamples.length > 0) {
        // setResponse is problematic, different TransloaditAssemblyListRequest responses can override each other
        // as the request keeps retrying with the same js context until all the assemblies are completed
        // using a ref as a helper workaround

        list.current = list.current.concat(newVoiceSamples);

        if (response) {
          setResponse({ ...response, voiceSamples: list.current });
        }
      }
    },
  });

  const { request: createAssemblies } =
    useAPIRequest<TransloaditAssemblyParams>(
      (assemblyIds: string[]) =>
        CreateVoiceSampleTransloaditAssembliesRequest(
          user?.id || '',
          assemblyIds,
        ),
      {
        immediate: false,
        onSuccess: () => {
          if (!isLoadingTransloaditAssemblyList) getTransloaditAssemblyList();
        },
      },
    );

  const [uppy] = useState(() => {
    return new Uppy({ restrictions: { allowedFileTypes: ['audio/*'] } })
      .use(Transloadit, {
        assemblyOptions: () => {
          return {
            params: {
              auth: { key: process.env.REACT_APP_TRANSLOADIT_AUTH_KEY! },
              template_id:
                process.env.REACT_APP_TRANSLOADIT_TEMPLATE_VOICE_SAMPLE,
            },
            fields: {
              user_id: user?.id || '',
            },
          };
        },
      })
      .on('upload-success', (value: any) => {
        // called once per file, not per assembly

        const assembly = value?.transloadit?.assembly;

        if (!assemblyId.current.includes(assembly)) {
          assemblyId.current.push(assembly);
        }
      })
      .on('complete', () => {
        if (assemblyId.current?.length > 0) {
          createAssemblies(assemblyId.current);
        }

        assemblyId.current = [];
      });
  });

  const { request: deleteVoiceSample } = useAPIRequest<VoiceSampleParams>(
    DeleteVoiceSampleRequest,
    {
      immediate: false,
      onSuccess: (value) => {
        const index = list.current.findIndex((item) => item.id === value.id);

        if (index !== -1) {
          list.current.splice(index, 1);

          if (response) {
            setResponse({ ...response, voiceSamples: list.current });
          }
        }
      },
      onError: requestErrorHandler(),
    },
  );

  const totalFilesProcessing =
    transloaditAssemblyListResponse?.totalFilesProcessing || 0;

  const durationSeconds =
    response?.voiceSamples?.reduce(
      (total, voiceSample) => total + voiceSample.durationSeconds,
      0,
    ) || 0;

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <ul style={{ color: 'var(--white)' }}>
        <li>
          Record yourself speaking for at least {REQUIRED_MINUTES} minutes
        </li>

        <li>Speak in the same way you want your AI clone to speak</li>

        <li>
          Use a high quality microphone - e.g. iPhone or studio microphone
        </li>

        <li>Avoid background noise</li>

        <li>Avoid echo</li>
      </ul>

      <Form.Item
        name="voiceSamples"
        rules={[
          () => ({
            validator() {
              if (response && !hasSufficientVoiceSamples(response)) {
                return Promise.reject(
                  new Error(
                    `Please upload at least ${REQUIRED_MINUTES} minutes of voice samples`,
                  ),
                );
              }

              return Promise.resolve();
            },
          }),
        ]}
        style={{ width: '100%' }}
      >
        <Dashboard
          uppy={uppy}
          width="100%"
          height="300px"
          style={{ marginTop: '20px' }}
        />
      </Form.Item>

      <If condition={totalFilesProcessing > 0}>
        <Space
          direction="horizontal"
          size="middle"
          style={{ color: 'var(--white)', marginTop: '20px' }}
        >
          <div>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
          {`Processing ${totalFilesProcessing} voice sample${
            totalFilesProcessing !== 1 ? 's' : ''
          }...`}
        </Space>
      </If>

      <If condition={(response?.voiceSamples.length || 0) > 0}>
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={response?.voiceSamples}
          renderItem={(item) => (
            <List.Item
              actions={[
                <a href={item.url} target="_blank">
                  <Icon component={PlaySvg} style={{ color: 'var(--grey6)' }} />
                </a>,
                <DeleteOutlined
                  style={{ color: 'var(--grey6)' }}
                  onClick={() =>
                    confirmDangerousAction({
                      action: 'delete',
                      actionText: 'Yes',
                      cancelText: 'No',
                      name: 'this voice sample',
                      dangerColor: true,
                      onConfirm: () => deleteVoiceSample(item.id),
                    })
                  }
                />,
              ]}
            >
              <List.Item.Meta
                title={<div style={{ color: 'var(--grey6)' }}>{item.name}</div>}
                description={
                  <div style={{ color: 'var(--grey5)' }}>{item.duration}</div>
                }
              />
            </List.Item>
          )}
          style={{ width: '100%' }}
        />
      </If>

      <VoiceSampleProgress
        durationSeconds={durationSeconds}
        requiredSeconds={REQUIRED_MINUTES * 60}
      />
    </div>
  );
};

export default VoiceSamples;
