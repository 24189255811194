import { useParams } from 'react-router-dom';
import { Space } from 'antd';
import Icon from '@ant-design/icons';

import {
  ChatCharacter,
  FindCharacterRequest,
  useAPIRequest,
} from '../../../../api';
import {
  CharacterHighlight,
  ChatSvg,
  Features,
  If,
  Loader,
  PersonalitySvg,
  StarSvg,
  TitleIcon,
  WideButton,
  useLayoutDisplay,
} from '../../../../common';
import { useChatStore } from '../../../../stores';
import { cssObjectToString } from '../../../../utils';
import CallModal from '../call/call-modal.component';

import './character.component.css';

const Character: React.FC = () => {
  const params = useParams();

  const { isMobile } = useLayoutDisplay();

  const { character, setCharacter } = useChatStore();

  const { isLoading, error } = useAPIRequest<ChatCharacter>(
    () => FindCharacterRequest(params.handle!),
    {
      onStart: () => setCharacter(),
      onSuccess: (response) => setCharacter(response),
    },
  );

  const hasPersonality = (character?.personalityTraits?.length || 0) > 0;

  return (
    <>
      <Loader isLoading={isLoading} error={error}>
        <div className="character-container" style={{ marginBottom: '55px' }}>
          <CharacterHighlight
            data={character}
            style={{ marginBottom: '38px' }}
          />

          <div className="description" style={{ marginBottom: '12px' }}>
            {character?.bio}
          </div>

          <WideButton
            label={character ? 'Chat with ' + character.name : 'Chat'}
            to={`/${character?.handle}/chat`}
            accent
            className="chat-button"
          >
            <Icon component={ChatSvg} style={{ width: '28px' }} />
          </WideButton>
        </div>

        <TitleIcon
          label="Get Ready For"
          style={{
            paddingLeft: '16px',
            marginBottom: '18px',
            color: 'var(--orange4)',
          }}
        >
          <Icon component={StarSvg} style={{ width: '22px' }} />
        </TitleIcon>

        <Features style={{ padding: '0px 20px', marginBottom: '32px' }} />

        <If condition={hasPersonality}>
          <TitleIcon
            label="Personality"
            style={{
              paddingLeft: '16px',
              marginBottom: '18px',
              color: 'var(--grey5)',
            }}
          >
            <Icon component={PersonalitySvg} style={{ width: '22px' }} />
          </TitleIcon>
        </If>

        <If condition={hasPersonality}>
          <Space
            direction="horizontal"
            align="center"
            size={8}
            className={cssObjectToString({
              'tags-container': true,
              mobile: isMobile,
            })}
            wrap={!isMobile}
          >
            {character?.personalityTraits.map((tag: string) => {
              return (
                <div className="tag" key={tag}>
                  {tag}
                </div>
              );
            })}
          </Space>
        </If>
      </Loader>
      <CallModal />
    </>
  );
};

export default Character;
