import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AsyncPipelineItem,
  AsyncPipelineLoader,
  CenteredContainer,
} from '../../../common';
import { useAuthStore, useInitStore } from '../../../stores';
import { apiRequest, ReadUserRequest } from '../../../api';

const Init: FC = () => {
  const navigate = useNavigate();

  const clearToken = useAuthStore((state) => state.clearToken);

  const { setUser, setInitialized, resetInitData } = useInitStore(); // , setSubscriptionPlans

  const pipeline: AsyncPipelineItem[] = [
    {
      key: 'profile',
      factory: () => apiRequest(ReadUserRequest()),
      onSuccess: setUser,
    },
    /*{
      key: 'subscriptionPlans',
      factory: () => apiRequest(SubscriptionPlansRequest()),
      onSuccess: (data) => setSubscriptionPlans(data.data),
    },*/
  ];

  return (
    <CenteredContainer vertical>
      <AsyncPipelineLoader
        pipeline={pipeline}
        options={{
          onSuccess: setInitialized,
          onError: (e) => {
            if (e.message === 'User not found') {
              resetInitData();
              clearToken();

              navigate('/');
            }
          },
        }}
      />
    </CenteredContainer>
  );
};

export default Init;
