import { Avatar, Layout, Space } from 'antd';
import Icon from '@ant-design/icons';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ChatSvg,
  CreatorSvg,
  DiscordSvg,
  ExploreSvg,
  If,
  MediaSvg,
  MoreSvg,
  NavigationButton,
  ProductSvg,
  ProfileSvg,
  StarSvg,
  WalletSvg,
  WideButton,
  useLayoutDisplay,
} from '../../common';
import { useInitStore, useMiscStore } from '../../stores';
import { RouteData } from '../../utils';
import { UserType } from '../../api';
import { BackSvg } from '../layout-header-svg.component';

import './app-sider.component.css';

const { Sider } = Layout;

export const AppSider: FC<{ routeData: RouteData }> = () => {
  const location = useLocation();

  const { displaySider, siderWidth } = useLayoutDisplay();

  const user = useInitStore((state) => state.user);
  const setMenuOpen = useMiscStore((state) => state.setMenuOpen);

  const isCreatorRoute = location.pathname.includes('/creator');

  if (!displaySider) return <></>;

  return (
    <>
      <div style={{ width: siderWidth }} />

      <Sider trigger={null} width={siderWidth}>
        <div className="app-sider-content">
          <div style={{ padding: '2px 14px 0px 14px' }}>
            <div style={{ width: '100%' }}>
              <Space
                direction="horizontal"
                size={0}
                style={{ marginBottom: '30px' }}
              >
                <img src="/logo/icon.png" />

                <div style={{ marginLeft: '10px', userSelect: 'none' }}>
                  Swoons
                </div>
              </Space>
            </div>

            <If condition={!isCreatorRoute}>
              <Space direction="vertical" size={12}>
                <NavigationButton label="Explore" to="/">
                  <Icon component={ExploreSvg} style={{ width: '16px' }} />
                </NavigationButton>

                <NavigationButton label="Messages" to="/messages">
                  <Icon component={ChatSvg} style={{ width: '16px' }} />
                </NavigationButton>

                <NavigationButton label="Wallet" to="/wallet">
                  <Icon component={WalletSvg} style={{ width: '16px' }} />
                </NavigationButton>

                {/*<If
                condition={
                  user?.subscription.active || (user?.balance || 0) > 0
                }
              >
                <NavigationButton label="Wallet" to="/wallet">
                  <Icon component={WalletSvg} style={{ width: '16px' }} />
                </NavigationButton>
              </If>

              <NavigationButton label="Subscription" to="/subscription">
                <Icon component={SubscriptionSvg} style={{ width: '16px' }} />
              </NavigationButton>*/}

                <NavigationButton label="Account" to="/account">
                  <If condition={user?.avatarUrl}>
                    <Avatar src={user?.avatarUrl} size={32} />
                  </If>

                  <If condition={!user?.avatarUrl}>
                    <Icon component={ProfileSvg} style={{ width: '16px' }} />
                  </If>
                </NavigationButton>

                <If
                  condition={
                    user?.type === UserType.Creator ||
                    user?.type === UserType.Admin
                  }
                >
                  <NavigationButton label="Creator" to="/creator">
                    <Icon component={StarSvg} style={{ width: '16px' }} />
                  </NavigationButton>
                </If>

                <NavigationButton
                  label="More"
                  onClick={() => setMenuOpen(true)}
                >
                  <Icon component={MoreSvg} style={{ width: '16px' }} />
                </NavigationButton>
              </Space>
            </If>

            <If condition={isCreatorRoute}>
              <If condition={user?.creatorOnboardingCompleted}>
                <Space direction="vertical" size={12}>
                  <NavigationButton label="Overview" to="/creator">
                    <Icon component={StarSvg} style={{ width: '16px' }} />
                  </NavigationButton>

                  <NavigationButton label="Profile" to="/creator/profile">
                    <Icon component={ProfileSvg} style={{ width: '16px' }} />
                  </NavigationButton>

                  {/*<NavigationButton label="About" to="/creator/about">
                    <Icon component={ChatSvg} style={{ width: '16px' }} />
                  </NavigationButton>*/}

                  <NavigationButton label="Media" to="/creator/media">
                    <Icon component={MediaSvg} style={{ width: '16px' }} />
                  </NavigationButton>

                  <NavigationButton label="Products" to="/creator/products">
                    <Icon component={ProductSvg} style={{ width: '16px' }} />
                  </NavigationButton>

                  <NavigationButton label="Payouts" to="/creator/payouts">
                    <Icon component={WalletSvg} style={{ width: '16px' }} />
                  </NavigationButton>

                  <NavigationButton label="Back" to="/">
                    <Icon component={BackSvg} style={{ width: '16px' }} />
                  </NavigationButton>
                </Space>
              </If>

              <If condition={!user?.creatorOnboardingCompleted}>
                <Space direction="vertical" size={12}>
                  <NavigationButton label="Onboarding" to="/creator">
                    <Icon component={StarSvg} style={{ width: '16px' }} />
                  </NavigationButton>

                  <NavigationButton label="Back" to="/">
                    <Icon component={BackSvg} style={{ width: '16px' }} />
                  </NavigationButton>
                </Space>
              </If>
            </If>
          </div>

          <Space direction="vertical" size={12}>
            <WideButton
              label="Discord"
              to={process.env.REACT_APP_DISCORD}
              target="_blank"
            >
              <Icon component={DiscordSvg} style={{ width: '16px' }} />
            </WideButton>

            <If condition={!user || user?.type === UserType.Normal}>
              <WideButton
                label="Creator?"
                to={process.env.REACT_APP_CREATORS}
                target="_blank"
                accent
              >
                <Icon component={CreatorSvg} style={{ width: '16px' }} />
              </WideButton>
            </If>
          </Space>
        </div>
      </Sider>
    </>
  );
};
