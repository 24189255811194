import { Form, Input, Modal, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAPIRequest, formRequestErrorHandler } from '../../../../api';
import { createNotification, NotificationType } from '../../../../utils';
import {
  CreateProductRequest,
  ProductParams,
} from '../../../../api/requests/product';
import { WideButton } from '../../../../common';

const { Title } = Typography;

const CreateProduct: React.FC<{
  characterId?: string;
  style?: React.CSSProperties;
}> = ({ characterId, style }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const { request, response, isLoading } = useAPIRequest<ProductParams>(
    (data) => CreateProductRequest(characterId!, data),
    {
      immediate: false,
      onSuccess: (response) => {
        createNotification({
          key: 'productCreated',
          message: 'Product created successfully.',
          type: NotificationType.Success,
          duration: 6,
        });

        setOpen(false);

        navigate(`/creator/product/${response.id}`);
      },
      onError: formRequestErrorHandler(form),
    },
  );

  return (
    <>
      <WideButton
        label="New Product"
        onClick={() => setOpen(true)}
        style={{ width: '160px', ...style }}
        light
      >
        <PlusOutlined />
      </WideButton>

      <Modal
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        closable={!isLoading}
        maskClosable={false}
        width="80%"
        style={{ maxWidth: '500px' }}
      >
        <Title
          style={{
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          New Product
        </Title>

        <Form
          form={form}
          onFinish={request}
          initialValues={response}
          layout="vertical"
          requiredMark={false}
          disabled={isLoading}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: 'Name is required' },
              {
                max: 30,
                message: 'Name must be maximum 30 characters',
              },
            ]}
          >
            <Input
              placeholder="Ex: Epomaker TH80 Pro"
              style={{ maxWidth: '500px' }}
            />
          </Form.Item>

          <Space style={{ marginTop: '10px' }}>
            <WideButton
              label="Cancel"
              disabled={isLoading}
              onClick={() => setOpen(false)}
              style={{ width: '160px' }}
              light
            />

            <WideButton
              label="Create"
              disabled={isLoading}
              onClick={form.submit}
              style={{ width: '160px' }}
              accent
            />
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default CreateProduct;
