import { CreatorOnboardingParams } from '../../../../api';
import { Step } from './step.interface';
import Preferences from './steps/preferences.component';
// import About from './steps/about.component';
import AICloneInformation from './steps/ai-clone-information.component';
// import BankAccountDetails from './steps/bank-account-details.component';
import PersonalInformation from './steps/personal-information.component';
import VoiceSamples from './steps/voice-samples.component';
// import UserAgreement from './steps/user-agreement.component';

export const hasSufficientVoiceSamples = (
  response: CreatorOnboardingParams,
) => {
  const durationSeconds = response?.voiceSamples?.reduce(
    (total, voiceSample) => total + voiceSample.durationSeconds,
    0,
  );

  return durationSeconds >= 2 * 60;
  // return durationSeconds >= 30 * 60;
};

export const STEPS: Step[] = [
  /*{
    component: UserAgreement,
    title: 'User Agreement',
    params: ['agreed'],
    nextText: 'Agree',
    staticSubmitValues: { agreed: true },
    disableRevisit: true,
  },*/
  {
    component: PersonalInformation,
    title: 'Personal Information',
    params: ['firstName', 'lastName', 'country'],
  },
  {
    component: AICloneInformation,
    title: 'AI Clone Information',
    params: ['displayName', 'handle', 'gender', 'language'],
  },
  {
    component: Preferences,
    title: 'Preferences',
    params: [
      'expertSubjects',
      'likeSubjects',
      'dislikeSubjects',
      'responseLength',
    ],
  },
  /*{
    component: About,
    title: 'About',
    description: 'Write everything you want your fans to know about you',
    params: ['background'],
  },
  {
    component: BankAccountDetails,
    title: 'Bank Account Details',
    description: `We'll use it to send you monthly payouts`,
    params: [
      'accountHolderName',
      'accountNumber',
      'swiftOrIbanCode',
      'bankCode',
      'routingOrTransitNumber',
      'recipientAddress',
      'recipientPhoneNumber',
    ],
  },*/
  {
    component: VoiceSamples,
    title: 'Voice Samples',
    description: `We'll use them to clone your voice`,
    nextText: 'Finish',
    staticSubmitValues: { finished: true },
    isStepComplete: hasSufficientVoiceSamples,
  },
];
