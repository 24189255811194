import { Drawer, Space } from 'antd';
import Icon from '@ant-design/icons';
import { FC } from 'react';

import { useLayoutDisplay } from '../../hooks';
import {
  CloseSvg,
  CreatorSvg,
  DiscordSvg,
  ExitSvg,
  MediaSvg,
  ProductSvg,
  ProfileSvg,
  StarSvg,
} from '../common-svg.component';
import { MenuDrawerLink } from './menu-drawer-link.component';
import {
  PrivacySvg,
  SignOutSvg,
  SupportSvg,
  TermsSvg,
} from './menu-drawer-link-svg.component';
import { useInitStore, useAuthStore, useMiscStore } from '../../../stores';
import { WideButton } from '../wide-button/wide-button.component';
import { If } from '../if.component';
import {
  ChatSvg,
  ExploreSvg,
  WalletSvg,
} from '../navigation-button/navigation-button-svg.component';
import { UserType } from '../../../api';
import {
  clearMixpanelUser,
  trackMixpanelEvent,
} from '../../../third-party/libraries/mixpanel';

import './menu-drawer.component.css';

export const MenuDrawer: FC = () => {
  const { isMobile } = useLayoutDisplay();

  const { menuOpen, setMenuOpen } = useMiscStore();
  const { token, clearToken } = useAuthStore();
  const { user, resetInitData } = useInitStore();

  const close = () => setMenuOpen(false);

  const isCreatorRoute = location.pathname.includes('/creator');

  // const hasBalance = (user?.balance || 0) > 0;

  const isCreator =
    user?.type === UserType.Creator || user?.type === UserType.Admin;

  let height: number | undefined = undefined;

  if (isMobile) {
    if (token) {
      height = 560;

      if (isCreatorRoute) {
        if (user?.creatorOnboardingCompleted) {
          height = 590;
        } else height = 430;
      }

      // if (user?.subscription.active || hasBalance) height += 40;
    } else height = 340;
  }

  return (
    <Drawer
      open={menuOpen}
      placement={isMobile ? 'bottom' : 'right'}
      closeIcon={null}
      onClose={close}
      height={height}
    >
      <div className="menu-drawer-container">
        <div className="button-circle" onClick={close}>
          <Icon component={CloseSvg} />
        </div>

        <div className="title">More</div>

        <Space direction="vertical" size={18} style={{ padding: '0px 20px' }}>
          <If condition={token && isMobile}>
            <If condition={!isCreatorRoute}>
              <Space direction="vertical" size={18} style={{ width: '100%' }}>
                <MenuDrawerLink
                  icon={ExploreSvg}
                  iconWidth="24px"
                  label="Explore"
                  to="/"
                  onClick={close}
                />

                <MenuDrawerLink
                  icon={ChatSvg}
                  iconWidth="24px"
                  label="Messages"
                  to="/messages"
                  onClick={close}
                />

                <MenuDrawerLink
                  icon={WalletSvg}
                  iconWidth="24px"
                  label="Wallet"
                  to="/wallet"
                  onClick={close}
                />

                {/*<If condition={user?.subscription.active || hasBalance}>
                <MenuDrawerLink
                  icon={WalletSvg}
                  iconWidth="24px"
                  label="Wallet"
                  to="/wallet"
                  onClick={close}
                />
              </If>

              <MenuDrawerLink
                icon={SubscriptionSvg}
                iconWidth="24px"
                label="Subscription"
                to="/subscription"
                onClick={close}
              />*/}

                <MenuDrawerLink
                  icon={ProfileSvg}
                  iconWidth="24px"
                  label="Account"
                  to="/account"
                  onClick={close}
                />

                <If condition={isCreator}>
                  <MenuDrawerLink
                    icon={StarSvg}
                    iconWidth="24px"
                    label="Creator"
                    to="/creator"
                    onClick={close}
                  />
                </If>
              </Space>
            </If>

            <If condition={isCreatorRoute}>
              <If condition={user?.creatorOnboardingCompleted}>
                <Space direction="vertical" size={18} style={{ width: '100%' }}>
                  <MenuDrawerLink
                    icon={StarSvg}
                    iconWidth="24px"
                    label="Overview"
                    to="/creator"
                    onClick={close}
                  />

                  <MenuDrawerLink
                    icon={ProfileSvg}
                    iconWidth="24px"
                    label="Profile"
                    to="/creator/profile"
                    onClick={close}
                  />

                  <MenuDrawerLink
                    icon={MediaSvg}
                    iconWidth="24px"
                    label="Media"
                    to="/creator/media"
                    onClick={close}
                  />

                  <MenuDrawerLink
                    icon={ProductSvg}
                    iconWidth="24px"
                    label="Products"
                    to="/creator/products"
                    onClick={close}
                  />

                  <MenuDrawerLink
                    icon={WalletSvg}
                    iconWidth="24px"
                    label="Payouts"
                    to="/creator/payouts"
                    onClick={close}
                  />

                  <MenuDrawerLink
                    icon={ExitSvg}
                    iconWidth="24px"
                    label="Exit Creator Settings"
                    to="/"
                    onClick={close}
                  />
                </Space>
              </If>

              <If condition={!user?.creatorOnboardingCompleted}>
                <Space direction="vertical" size={18} style={{ width: '100%' }}>
                  <MenuDrawerLink
                    icon={StarSvg}
                    iconWidth="24px"
                    label="Onboarding"
                    to="/creator"
                    onClick={close}
                  />

                  <MenuDrawerLink
                    icon={ExitSvg}
                    iconWidth="24px"
                    label="Exit Creator Settings"
                    to="/"
                    onClick={close}
                  />
                </Space>
              </If>
            </If>
          </If>

          <MenuDrawerLink
            icon={TermsSvg}
            label="Terms & Conditions"
            to="/tos"
            onClick={close}
          />

          <MenuDrawerLink
            icon={PrivacySvg}
            label="Privacy Policy"
            to="/privacy"
            onClick={close}
          />

          <MenuDrawerLink
            icon={SupportSvg}
            label="Support"
            to={'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL}
            target="_blank"
            onClick={close}
          />

          <If condition={!!token}>
            <MenuDrawerLink
              icon={SignOutSvg}
              label="Sign Out"
              to="/"
              onClick={() => {
                resetInitData();
                clearToken();
                close();

                trackMixpanelEvent('Sign Out');
                clearMixpanelUser();
              }}
            />
          </If>
        </Space>

        <If condition={isMobile}>
          <Space
            direction="vertical"
            size={12}
            style={{ padding: '0px 20px', marginTop: '32px' }}
          >
            <WideButton
              label="Discord"
              to={process.env.REACT_APP_DISCORD}
              target="_blank"
            >
              <Icon component={DiscordSvg} style={{ width: '16px' }} />
            </WideButton>

            <If condition={!isCreator}>
              <WideButton
                label="Creator?"
                to={process.env.REACT_APP_CREATORS}
                target="_blank"
                accent
              >
                <Icon component={CreatorSvg} style={{ width: '16px' }} />
              </WideButton>
            </If>
          </Space>
        </If>
      </div>
    </Drawer>
  );
};
