import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';

import { CenteredContainer } from '../../../common';
import { useInitStore, usePersistedStore } from '../../../stores';
import {
  useAPIRequest,
  EmailParams,
  UpdateEmailRequest,
  formRequestErrorHandler,
  SendConfirmEmailRequest,
} from '../../../api';
import { createNotification, NotificationType } from '../../../utils';

import '../../../common/form.css';

const ConfirmEmail: React.FC = () => {
  const navigate = useNavigate();
  const defaultPath = usePersistedStore((state) => state.defaultPath);
  const { user, setEmail } = useInitStore();

  const [form] = Form.useForm();

  // form.getFieldValue('email') needs a state refresh to work and takes time to become available on page load
  const [emailField, setEmailField] = useState<string>(user?.email as string);

  const notify = (email: string) =>
    createNotification({
      key: email,
      message: `Confirmation email sent to ${email}. Please check your inbox.`,
      type: NotificationType.Success,
      duration: 6,
    });

  const { request: requestChangeEmail, isLoading: isChangingEmail } =
    useAPIRequest<EmailParams>(UpdateEmailRequest, {
      immediate: false,
      onSuccess: (response) => {
        setEmail(response.email, response.emailConfirmed);
        notify(response.email);
      },
      onError: formRequestErrorHandler(form),
    });

  const {
    request: requesResendConfirmationEmail,
    isLoading: isResendinigConfirmationEmail,
  } = useAPIRequest<EmailParams>(SendConfirmEmailRequest, {
    immediate: false,
    onSuccess: () => notify(user!.email!),
    onError: formRequestErrorHandler(form),
  });

  if (user?.emailConfirmed && defaultPath) navigate(defaultPath);

  const isLoading = isChangingEmail || isResendinigConfirmationEmail;

  return (
    <CenteredContainer className="form" vertical style={{ width: '100%' }}>
      <div className="title" style={{ marginBottom: '24px' }}>
        Confirm your Email Address
      </div>

      <Form
        form={form}
        onFinish={
          emailField !== user?.email
            ? requestChangeEmail
            : requesResendConfirmationEmail
        }
        initialValues={{ email: user?.email }}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        disabled={isLoading}
        style={{ width: '90%', maxWidth: '400px' }}
      >
        <div className="description" style={{ marginBottom: '24px' }}>
          Confirmation email sent to {user?.email}. Please check your inbox.
        </div>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Email must not be empty',
            },
            {
              type: 'email',
              message: 'Email is invalid',
            },
          ]}
        >
          <Input
            placeholder="Email"
            onChange={(e) => setEmailField(e.currentTarget.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="form-button light"
          >
            {emailField !== user?.email
              ? 'Change Email'
              : 'Resend Confirmation Email'}
          </Button>
        </Form.Item>
      </Form>
    </CenteredContainer>
  );
};

export default ConfirmEmail;
