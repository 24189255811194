import { Drawer, Space } from 'antd';
import Icon from '@ant-design/icons';
import { FC } from 'react';

import {
  useAPIRequest,
  UserParams,
  UpdateResponseType,
  ResponseType,
} from '../../../../../api';
import {
  useLayoutDisplay,
  CloseSvg,
  VoiceSvg,
  MenuDrawerLink,
  SupportSvg,
  TermsSvg,
  MenuDrawerSwitch,
  WalletSvg,
} from '../../../../../common';
import {
  useChatStore,
  useInitStore,
  useMiscStore,
} from '../../../../../stores';

import './chat-drawer.component.css';

export const ChatDrawer: FC = () => {
  const { isMobile } = useLayoutDisplay();

  const { chatMenuOpen, setChatMenuOpen, setPricingMenuOpen } = useChatStore();
  const { user, setResponseType } = useInitStore();
  const { setWalletOpen } = useMiscStore();

  const close = () => setChatMenuOpen(false);

  const { request: toggleResponseType, isLoading: isTogglingResponseType } =
    useAPIRequest<UserParams>(
      () =>
        UpdateResponseType(
          user?.responseType === ResponseType.Text
            ? ResponseType.Voice
            : ResponseType.Text,
        ),
      {
        immediate: false,
        onSuccess: (response) => {
          setResponseType(response.responseType);
        },
      },
    );

  return (
    <Drawer
      open={chatMenuOpen}
      placement={isMobile ? 'bottom' : 'right'}
      closeIcon={null}
      onClose={close}
      height={280}
    >
      <div className="chat-drawer-container">
        <div className="button-circle" onClick={close}>
          <Icon component={CloseSvg} />
        </div>

        <div className="title">Chat Settings</div>

        <Space direction="vertical" size={18} style={{ padding: '0px 20px' }}>
          <MenuDrawerSwitch
            icon={VoiceSvg}
            iconWidth="24px"
            label="Voice Responses"
            value={user?.responseType === ResponseType.Voice}
            onClick={toggleResponseType}
            disabled={isTogglingResponseType}
          />

          <MenuDrawerLink
            icon={WalletSvg}
            iconWidth="24px"
            label={`Balance: $${user?.balance?.toFixed(2)}`}
            onClick={() => {
              setWalletOpen(true);
              close();
            }}
          />

          <MenuDrawerLink
            icon={TermsSvg}
            label="Pricing"
            onClick={() => {
              setPricingMenuOpen(true);
              close();
            }}
          />

          <MenuDrawerLink
            icon={SupportSvg}
            label="Support"
            to={'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL}
            target="_blank"
            onClick={close}
          />
        </Space>
      </div>
    </Drawer>
  );
};
