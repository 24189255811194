import { Link } from 'react-router-dom';
import { Space } from 'antd';
import Icon from '@ant-design/icons';

import {
  ChatSvg,
  HeartSvg,
  TitleIcon,
  useLayoutDisplay,
} from '../../../common';
import { ChatCharacter } from '../../../api';
import { cssObjectToString } from '../../../utils';

import './index-creators.component.css';

const IndexCreators: React.FC<{
  data?: ChatCharacter[];
  style?: React.CSSProperties;
}> = ({ data, style }) => {
  const { isMobile } = useLayoutDisplay();

  return (
    <div className="index-creators-container" style={style}>
      <TitleIcon
        label="Most Popular"
        center={!isMobile}
        style={{
          marginLeft: '16px',
          marginRight: '16px',
          marginTop: '42px',
          marginBottom: '8px',
        }}
      >
        <Icon component={HeartSvg} style={{ width: '22px' }} />
      </TitleIcon>

      <Space
        direction="horizontal"
        align="center"
        size={10}
        className={cssObjectToString({
          'index-creators-list': true,
          mobile: isMobile,
        })}
      >
        {data?.map((character: ChatCharacter) => {
          return (
            <Link to={`/${character.handle}`} key={'character-' + character.id}>
              <Space
                direction="vertical"
                size={8}
                align="center"
                style={{ width: '100%' }}
                className="character-item"
              >
                <div
                  className="character-image"
                  style={{
                    backgroundImage: `url('${character.coverThumbnailUrl}')`,
                  }}
                >
                  <div className="chat-tag">
                    <Icon
                      component={ChatSvg}
                      style={{ width: '16px', marginRight: '4px' }}
                    />{' '}
                    Chat
                  </div>
                </div>

                <div className="character-name">{character.name}</div>
              </Space>
            </Link>
          );
        })}
      </Space>
    </div>
  );
};

export default IndexCreators;
