import { Row, Col, Card, Statistic, ConfigProvider } from 'antd';

import { Loader } from '../../../../common';
import {
  OverviewBillingPeriod,
  OverviewParams,
  OverviewRequest,
  useAPIRequest,
} from '../../../../api';
import { formatCurrency } from '../../../../utils';

const CreatorOverview: React.FC = () => {
  const { response, isLoading, error } =
    useAPIRequest<OverviewParams>(OverviewRequest);

  return (
    <Loader isLoading={isLoading} error={error} style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div className="inner-wrapper">
          <div className="title">Earnings</div>

          <ConfigProvider
            theme={{ token: { colorBgContainer: 'var(--grey3)' } }}
          >
            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title={<div style={{ color: 'var(--grey6)' }}>Current</div>}
                    value={formatCurrency(
                      response?.[OverviewBillingPeriod.Current] || 0,
                    )}
                    valueStyle={{ color: 'var(--white)' }}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title={
                      <div style={{ color: 'var(--grey5)' }}>All Time</div>
                    }
                    value={formatCurrency(
                      response?.[OverviewBillingPeriod.AllTime] || 0,
                    )}
                    valueStyle={{ color: 'var(--grey6)' }}
                  />
                </Card>
              </Col>
            </Row>
          </ConfigProvider>
        </div>
      </div>
    </Loader>
  );
};

export default CreatorOverview;
