import { Table } from 'antd';
import { FC } from 'react';
import { ColumnsType } from 'antd/es/table';

import {
  useAPIRequest,
  PayoutParams,
  PayoutListRequest,
} from '../../../../api';
import { Loader } from '../../../../common';
import { formatCurrency, formatDate } from '../../../../utils';

export const Payouts: FC = () => {
  const { response, error, isLoading } = useAPIRequest<{
    data: PayoutParams[];
  }>(PayoutListRequest);

  const columns: ColumnsType<PayoutParams> = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => {
        return <span>{formatCurrency(amount)}</span>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'created',
      render: (created: Date) => {
        return <span>{formatDate(created)}</span>;
      },
    },
  ];

  return (
    <Loader error={error} isLoading={isLoading}>
      <Table
        dataSource={response?.data}
        columns={columns}
        rowKey={(row) => row.id}
        style={{ padding: '20px' }}
      />
    </Loader>
  );
};
