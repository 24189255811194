import { Button, Col, Form, InputNumber, Row, Space, Switch } from 'antd';
import {
  SaveOutlined,
  DeleteOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useRequests,
  formRequestErrorHandler,
  getMediaRequests,
  MediaType,
} from '../../../../api';
import { Loader, CRUDKey, If, WideButton } from '../../../../common';
import {
  capitalizeFirstLetter,
  confirmDangerousAction,
  createNotification,
  NotificationType,
} from '../../../../utils';
import ImagePicker from './image-picker.component';

const rotations = [0, 90, 180, 270];

const Media: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [rotationIndex, setRotationIndex] = useState<number>(0);

  const { request, requestKey, response, isLoading, error } = useRequests({
    requests: getMediaRequests(params.mediaId!),
    callbacks: {
      onSuccess: (data, key) => {
        form.setFieldsValue(data);

        setPreviewIndex(data.previewIndex);
        setRotationIndex(0);

        if (key === CRUDKey.Update) {
          createNotification({
            key: 'mediaUpdated',
            message: 'Media saved successfully.',
            type: NotificationType.Success,
            duration: 6,
          });
        } else if (key === CRUDKey.Delete) {
          createNotification({
            key: 'mediaDeleted',
            message: 'Media deleted successfully.',
            type: NotificationType.Success,
            duration: 6,
          });

          navigate(`/creator/media`);
        }
      },
      onError: formRequestErrorHandler(form),
    },
  });

  useEffect(() => {
    if (params.mediaId) request.read();
  }, [params.mediaId]);

  const rotateLeft = () => {
    if (rotationIndex > 0) {
      setRotationIndex(rotationIndex - 1);
    } else {
      setRotationIndex(rotations.length - 1);
    }
  };

  const rotateRight = () => {
    if (rotationIndex < rotations.length - 1) {
      setRotationIndex(rotationIndex + 1);
    } else {
      setRotationIndex(0);
    }
  };

  return (
    <Loader
      error={requestKey === CRUDKey.Read ? error : undefined}
      isLoading={isLoading}
    >
      <Form
        form={form}
        onFinish={(params) => request.update(params, rotations[rotationIndex])}
        initialValues={response}
        layout="vertical"
        requiredMark={false}
      >
        <Row align="top" style={{ width: '100%', padding: '20px' }}>
          <Col flex="200px" style={{ marginRight: '40px' }}>
            <Form.Item label={capitalizeFirstLetter(response?.type || '')}>
              <a href={response?.url} target="_blank">
                <img
                  src={
                    response?.thumbnailUrls?.length || 0 > 0
                      ? response?.thumbnailUrls?.[previewIndex]
                      : response?.thumbnailUrl
                  }
                  style={{
                    width: '260px',
                    height: '260px',
                    objectFit: 'cover',
                    cursor: 'pointer',
                    transform: `rotate(${rotations[rotationIndex]}deg)`,
                  }}
                />
              </a>

              <If condition={response?.type === MediaType.Picture}>
                <Space direction="horizontal" size={0}>
                  <Button
                    icon={<RotateRightOutlined />}
                    style={{ border: 0, color: 'var(--white)' }}
                    onClick={rotateRight}
                  />

                  <Button
                    icon={<RotateLeftOutlined />}
                    style={{ border: 0, color: 'var(--white)' }}
                    onClick={rotateLeft}
                  />
                </Space>
              </If>
            </Form.Item>

            <If condition={response?.thumbnailUrls?.length || 0 > 0}>
              <Form.Item name="previewIndex">
                <ImagePicker
                  data={response?.thumbnailUrls}
                  onChange={(index) => setPreviewIndex(index)}
                />
              </Form.Item>
            </If>
          </Col>

          <Col flex="1" style={{ minWidth: 'min(450px, 100%)' }}>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <If condition={response?.name}>
                <Form.Item label="Name">
                  <b>{response?.name}</b>
                </Form.Item>
              </If>

              <If condition={response?.width || 0 > 0}>
                <Form.Item label="Dimensions">
                  <b>
                    {response?.width} x {response?.height}
                  </b>
                </Form.Item>
              </If>

              <If condition={response?.duration}>
                <Form.Item label="Duration">
                  <b>{response?.duration}</b>
                </Form.Item>
              </If>

              <Form.Item
                name="price"
                label="Price"
                tooltip="The price in US dollars that the user will be asked to pay to unlock this media."
                rules={[
                  {
                    required: true,
                    message: 'Price is required',
                  },
                ]}
              >
                <InputNumber min={0} style={{ maxWidth: '500px' }} />
              </Form.Item>

              <Form.Item
                name="welcome"
                label="Welcome"
                valuePropName="checked"
                tooltip="Whether or not this media will be attached to the welcome message in new chats."
              >
                <Switch />
              </Form.Item>

              <Form.Item
                name="reengagement"
                label="Reengagement"
                valuePropName="checked"
                tooltip="Whether or not this media can be used in reengagement messages. Users will still have to pay to unlock it if it's not free."
              >
                <Switch />
              </Form.Item>

              <Space>
                <WideButton
                  label="Save"
                  disabled={isLoading}
                  onClick={form.submit}
                  style={{ width: '160px' }}
                  accent
                >
                  <SaveOutlined />
                </WideButton>

                <WideButton
                  label="Delete"
                  disabled={isLoading}
                  onClick={() =>
                    confirmDangerousAction({
                      action: 'Delete',
                      name: 'this media',
                      onConfirm: request.delete,
                    })
                  }
                  style={{ width: '160px' }}
                  light
                >
                  <DeleteOutlined />
                </WideButton>
              </Space>
            </Space>
          </Col>
        </Row>
      </Form>
    </Loader>
  );
};

export default Media;
